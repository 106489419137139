<template>
    <div id="article">
        <h1 class="title">Am deschis un muzeu privat în București, după ce i-am „urmărit” pe turiștii străini prin Centrul Vechi, și am ajuns, după doi ani de la deschidere, numărul 1 pe Tripadvisor</h1>
        <p class="publish-date">2019-10-28 07:13:00</p>
        <img id="image1" :src="path1" />
        <p class="image1-details">Foto: Octavian Gheorghe/ Inquam Photos</p>
        <p class="paragraph">Înainte de a deschide Muzeul Kitschului, vizitasem deja vreo 400 de muzee, din peste 100 de țări. Estimez că am văzut mai multe muzee decât toți miniștrii Culturii de după 90 la un loc. Definiția muzeului este destul de vagă încă, chiar și pentru mine, însă putem admite că muzeele sunt locuri deschise publicului, unde oamenii vin să vadă lucruri rare sau unice, fie că vorbim despre artă plastică, vestigii istorice sau animale împăiate (muzeele cu animale vii se numesc grădini zoologice). Pe scurt, este vorba despre expunerea unei colecții, care prezintă un anumit interes, pentru public.</p>
        <p class="paragraph">Cele mai populare muzee din lume, dacă stăm să căutăm pe net, sunt ori 4, ori 5, ori 6. British Museum, Metropolitan NY, Luvrul și Muzeul Hermitage din Sankt Petersburg se regăsesc în mai toate topurile. Urmează, la fotografie, Muzeul Vaticanului și Prado din Madrid. Acestea sunt cele mai populare, mai vizitate și mai costisitor de întreținut muzee din lume. Totuși, în pofida valurilor de vizitatori, generatoare de cozi kilometrice la casierii, toate aceste muzee sunt incapabile să se autofinanțeze. Autofinanțarea este condiția esențială de funcționare doar pentru muzeele private, inclusiv cele de tip boutique, cele care nu primesc finanțare de la comunitate, ci se bazează aproape în totalitate pe vânzarea de bilete către vizitatori. Există în toată lumea astăzi o mulțime de muzee private, muzee ale torturilor medievale, ale sexului, ale figurilor de ceară sau altele, expunând teme mult mai specifice, așa cum ar fi Muzeul Poșetelor din Amsterdam sau Muzeul Despărțirilor din Zagreb. Toate sunt muzee, dacă atrag suficienți vizitatori care apreciază tema prezentată.</p>
        <p class="paragraph">Analizând oferta turistică din București, la începutul lui 2017, ca un călător încercat ce eram, am apreciat-o că fiind foarte slabă. Pe atunci, cele mai apreciate obiective turistice din București erau Parcul Herăstrău și Biserica Stavropoleos (probabil datorită poziționării sale în centrul vechi, în traficul turiștilor). Ca peste tot în lume, cea mai credibilă sursă de informare a turiștilor este aplicația tripadvisor, care se bazează strict pe review-urile altor turiști. Clasamentele de genul ăsta generalizează obiectivitatea și deseori creează surprize: În Paris de pildă, Turnul Eiffel nu ocupă decât un modest loc 10 în evaluările turiștilor. Totuși, nu am mai văzut nicăieri în lume, un parc ca fiind cea mai interesantă atracție turistică dintr-o metropolă. Central Park este pe locul 3 în New York iar Hyde Park abia pe locul 7 în Londra. Atunci când ai un parc pe primul loc, așa cum avea Bucureștiul în 2017, înseamnă că ești cam varză cu oferta turistică serioasă, adică muzee, monumente sau spectacole.</p>
        <p class="paragraph">Între Muzeul Comunismului și Muzeul Kitschului, a câștigat ideea mai haioasă. Nici eu, nici Adrian, partenerul meu, nu voiam să transmitem o experiență dramatică vizitatorilor noștri, ci una amuzantă. Dar la muzeu nu e ca la cinematograf, nu ai de ce să vii de mai multe ori, să vezi aceeași colecție. Astfel, majoritatea vizitatorilor muzeelor sunt de fapt turiști. Iar majoritatea turiștilor sunt de fapt străini. Din cauza asta, înainte de a alege locația, am urmărit turiști străini prin centrul vechi pentru a le observa rutele și am estimat traficul lor cu chelnerii localurilor care au avut amabilitatea să răspundă unui formular cu întrebări.</p>
        <img :src="path2" id="image2"/>
        <p class="paragraph">Să presupunem că ți-a venit un străin în vizită, care stă la tine câteva zile și care te întreabă ce ar putea să viziteze prin București. Unde îl trimiți? La întrebarea asta ne-am dorit să răspundem cu muzeul nostru. Am muncit mult la început, OLX, Dragonul Roșu, depozite de vechituri, pentru completarea colecției, sperând că, după deschidere, muzeul va merge de la sine. Mai târziu, am aflat că nu este deloc așa. Nu este nici ușor și nici foarte profitabil să ții un muzeu deschis tot timpul anului. Chiriile în centrul vechi sunt mari iar o zi ploioasă îți poate goni toți vizitatorii. Însă lucrurile s-au legat, oamenii s-au amuzat, experiența noastra le-a plăcut și au recomandat-o prietenilor.</p>
        <img id="image3" :src="path3" />
        <p class="paragraph">Astăzi, la doi ani și jumătate de la deschidere, Romanian Kitsch Museum este obiectivul turistic numărul 1 din București, și implicit din țară, <a class="conform-tripadvisor" href="https://www.tripadvisor.com/Attractions-g294458-Activities-c49-Bucharest.html">conform tripadvisor.</a> Aproape douăzeci de mii de curioși ne-au vizitat iar colecția de exponate s-a dublat, în pricipal datorită donațiilor primite de la simpatizanți. Șase dintre primele zece obiective din București sunt acum muzee. Top 10 al atracțiilor este completat de o biserică (tot Stavropoleos a rămas), două parcuri (Herăstrău și Al. I Cuza, Cișmigiu e pe #11) și un mall (!). Palatul Parlamentului, a două clădire ca dimensiune din lume, se situează pe un deplorabil loc #20 în topul atracțiilor din București, probabil și datorită accesului absurd de dificil pentru vizitatori.</p>
        <img id="image4" :src="path4" />
        <img id="image5" :src="path5" />
        <p class="paragraph">În lipsa altor obiective turistice îngrijite și prezentabile turiștilor străini, s-ar zice că muzeele sunt singurele atracții turistice serioase din București. Celălalt muzeu privat, Museum of Senses, s-a impus în top imediat după deschidere, lucru îmbucurător pentru noi, cei interesați de creșterea categoriei muzeelor private. Casa Ceaușescu completează podiumul muzeelor din București, beneficiind de mitul grandomaniei dictatorilor, care îi captivează pe turiști. </p>
        <p class="paragraph">În scădere vizibilă de popularitate sunt muzeele de stat, cel mai puțin adaptate la evoluția pieței turistice și aproape inexintente în digital. Muzeul Satului, Muzeul Antipa și Muzeul Colecțiilor de Artă abia reușesc să se mențină în top 10 al atracțiilor tripadvisor, în ciuda locațiilor excepționale, a colecțiilor impresionante și a bugetelor netransparente. Și mai ales în ciuda prețurilor derizorii la biletele de acces (2-4 euro/ bilet față de 6-8 euro/ muzeu privat). Practic, vizita turistului străin în muzeu este suportată parțial de contribuabilii locali, o absurditate socială. Restul celor peste cincizeci de muzee din București, plătite de contribuabili, se decurca și mai greu. Multe dintre ele au cu siguranță mai multe exponate decât vizitatori (Muzeul Național de Artă #17, Muzeul Enescu #35, Muzeul Militar #40, Muzeul Țăranului Român #51). Se confirmă și în acest caz că ceea ce e de stat, stă!</p>

    </div>
</template>

<script>
export default {
  data() {
    return {
      path1: require("@/assets/images/Articles/kitsch-museum1.jpg"),
      path2: require("@/assets/images/Articles/kitsch-museum2.jpg"),
      path3: require("@/assets/images/Articles/kitsch-museum3.jpg"),
      path4: require("@/assets/images/Articles/kitsch-museum4.jpg"),
      path5: require("@/assets/images/Articles/kitsch-museum5.jpg"),



    };
  },
};
</script>

<style lang="scss" scoped>
#article {
  position: relative;
  top: 10vh;
  width: 58vw;
  margin: auto;
  //   background-color: #1D0E0E;
  //   box-shadow: 0.1vw 0.1vw 1vw rgba(16.1%, 19.6%, 32.9%, 0.5);
  padding: 2vh 10vw 2vh 10vw;
  border-radius: 1vw;
  margin-bottom: 20vh;
  text-rendering: optimizeLegibility;
  text-align: left;
}
.title{
     margin-bottom: 0;
    font-family: "PT Serif Caption",serif;
    font-weight: 400;
    line-height: 1.4;
    color: inherit;
  color: #373a3c;
  text-align: left;
}

.publish-date{
  color: #818a91 !important;
  font-family: Raleway,sans-serif;
font-size: 1rem;
line-height: 1.5;
float:left;
margin-bottom: 2rem;

}


#image1{
  width: 100%;
}
#image2{
  width: 60%;
}
#image3{
  width: 60%;
}
#image4{
  width: 60%;
}
#image5{
  width: 60%;
}
img {
    display: block;
    margin: auto;
    height: auto;
    margin-bottom: 3vh;
    margin-top: 3vh;   
}


.image1-details{
  font-family: "PT Serif",serif;
font-size: 1.25rem;
line-height: 1.58;
margin-bottom: 5vh;
font-style:italic;
}

.paragraph{
  margin-bottom: 1.5rem;
  margin-top: 0;
  font-family: "PT Serif",serif;
font-size: 1.5rem;
line-height: 1.58;
color: #373a3c;
}

.conform-tripadvisor{
  color: #02a87c;
}

@media (max-width: 1000px) {
  #article{
    width: 90%;
    
  }

  
  #image2, #image3, #image4, #image5{
    width: 80%;
  }
}

@media(max-width: 700px) {
  #article {
    width: 100%;
    padding: 0 !important;
  }
}

@media(max-width: 1500px) {
  #article {
    width: 80%;
  }
}


</style>
